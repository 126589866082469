@use 'variables';

$bubble-radius-small: 4px;
$bubble-radius-large: 16px;

.str-chat {
  ::selection {
    color: white;
    background-color: variables.$gray-5;
  }
}

.str-chat__main-panel-inner {
  box-shadow: inset rgba(0, 0, 0, 0.1) 0px 12px 12px -12px;
}

.str-chat__message-list-scroll {
  // Make room for the loading indicator.
  padding-top: 20px !important;
  user-select: text;

  .str-chat__li {
    padding: 0 16px !important;
  }

  .str-chat__unread-messages-separator-wrapper {
    color: variables.$gray-7;
    background-color: variables.$gray-2;
    font-size: 12px;
    line-height: 1.5;
    margin-block: 16px;
    user-select: none;

    .str-chat__unread-messages-separator {
      padding: 4px 0; 
    }
  }
}

.str-chat__li--middle, .str-chat__li--bottom {
  .str-chat__message {
    margin-block: 3px 0;
  }

  .str-chat__message--me .str-chat__message-bubble {
    border-top-right-radius: $bubble-radius-small;
  }

  .str-chat__message--other .str-chat__message-bubble {
    border-top-left-radius: $bubble-radius-small;
  }
}

.str-chat__message {
  transition-duration: 0.3s;

  &.str-chat__message--me {
    grid-template-areas:
      "message"
      "translation-notice"
      "custom-metadata"
      "metadata"
      "replies";

    .str-chat__message-bubble {
      color: white;
      background-color: variables.$gray-7;
      border-color: variables.$gray-7;
      border-top-left-radius: $bubble-radius-large;
      border-bottom-left-radius: $bubble-radius-large;

      a {
        color: inherit;
      }
    }

    .str-chat__quoted-message-bubble {
      color: variables.$gray-9;
      background-color: variables.$gray-1;
      border-radius: $bubble-radius-large $bubble-radius-large $bubble-radius-large $bubble-radius-small;
    }
  }

  &.str-chat__message--other {
    grid-template-areas:
        "avatar message"
        ". translation-notice"
        ". custom-metadata"
        ". metadata"
        ". replies";

    .str-chat__message-bubble {
      border-top-right-radius: $bubble-radius-large;
      border-bottom-right-radius: $bubble-radius-large;
    }

    .str-chat__message-data {
      flex-direction: row-reverse;
    }
  }

  &.str-chat__message--highlighted {
    background-color: variables.$gray-1;
  }

  &--system {
    &__text {
      font-size: 14px;
      line-height: 1.5;
      color: variables.$gray-9;
      background-color: variables.$gray-1;
    }

    &__date {
      font-size: 12px;
      line-height: 1.5;
      color: variables.$gray-7;
      margin-top: 4px;
    }
  }

  .str-chat__message-bubble {
    color: variables.$gray-9;
    background-color: variables.$gray-1;
    border: 1px solid variables.$gray-2;
    border-radius: $bubble-radius-large $bubble-radius-large $bubble-radius-small $bubble-radius-small;
  
    .str-chat__message-text {
      font-size: 16px;
      line-height: 1.5;
      padding: 8px 16px !important;
    }

    a {
      color: inherit;
    }
  }

  .str-chat__quoted-message-preview {
    cursor: pointer;

    .str-chat__message-sender-avatar {
      display: none;
    }

    .str-chat__quoted-message-bubble {
      padding: 8px 16px;
    }
  }

  .str-chat__attachment-list {
    $gap: 4px;

    gap: $gap;

    .str-chat__message-attachment {
      margin: 0 $gap;
      color: variables.$gray-9;
      background-color: white;
      border-radius: $bubble-radius-large - $gap;
  
      &:first-of-type {
        margin-top: $gap;
      }
  
      &:last-of-type {
        margin-bottom: $gap;
      }

      .str-chat__message-attachment-file--item {
        margin: 0;
        padding: 8px;
        column-gap: 12px;

        .str-chat__message-attachment-file--item-text {
          gap: 0;
          line-height: 1.5;
          color: variables.$gray-8;

          .str-chat__message-attachment-file--item-name {
            color: variables.$gray-8;
            font-size: 14px;
            font-weight: variables.$font-weight-bold;
          }

          .str-chat__message-attachment-file--item-size {
            font-size: 12px;
            color: variables.$gray-5;
          }
        }
      }

      .str-chat__message-attachment-download-icon {
        --str-chat-icon-height: 24px;
      }
    }
  }

  .str-chat__message-options {
    color: variables.$gray-5;
    user-select: none;
  }

  .str-chat__message-data {
    font-size: 14px;
    line-height: 1.5;
    color: variables.$gray-5;
    margin-top: 4px;

    .str-chat__message-status {
      color: variables.$gray-4;
    }
  }

  .str-chat__mesage-simple-edited {
    display: flex;
    align-items: center;

    &:before {
      font-size: 8px;
    }
  }

  .str-chat__message-reactions-list {
    overflow: visible !important;

    .str-chat__message-reactions-list-item {
      border-radius: variables.$radius-medium;
      transition-duration: 0.2s;

      &.str-chat__message-reactions-option-selected {
        background-color: variables.$gray-1;
      }

      @media (hover: hover) {
        &:hover {
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .str-chat__message-reactions {
    gap: 6px;
    margin-bottom: 4px;
    user-select: none;

    .str-chat__message-reaction {
      color: variables.$gray-9;
      background-color: variables.$gray-1;
      font-weight: variables.$font-weight-bold;
      padding: 8px;
      border-radius: variables.$radius-medium;
    }

    .str-chat__message-reaction-own {
      color: white;
      background-color: variables.$gray-7;
    }
  }

  .str-chat__message-reactions-details {
    .str-chat__message-reactions-details-reaction-types {
      $shadow-clearance: 6px;

      gap: 6px;
      padding: $shadow-clearance;
      margin: -$shadow-clearance;

      .str-chat__message-reactions-details-reaction-type {
        color: variables.$gray-9;
        font-size: 16px;
        font-weight: variables.$font-weight-bold;
        padding: 8px;
        border-radius: variables.$radius-medium;
        transition-duration: 0.2s;

        @media (hover: hover) {
          &:hover {
            background-color: variables.$gray-1;
          }
        }
      }

      .str-chat__message-reactions-details-reaction-type--selected {
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
        pointer-events: none;
      }
    }

    .str-chat__message-reactions-details-reacting-users {
      max-height: 400px;
    }

    .str-chat__message-reactions-details-reacting-user {
      gap: 16px;
    }

    .str-chat__avatar {
      --str-chat__avatar-size: 48px;
      
      .str-chat__avatar-fallback {
        font-size: 24px;
      }
    }

    .str-chat__user-item--name {
      color: variables.$gray-9;
      font-size: 14px;
      font-weight: variables.$font-weight-bold;
    }
  }

  .str-chat__message-replies-count-button {
    background-color: transparent;
    font-size: 14px;
    line-height: 1.5;
    color: variables.$gray-8;
    padding: 3px !important;
    outline: none;
    transition-duration: 0.2s;

    &:focus-visible {
      color: variables.$gray-5;
    }

    @media (hover: hover) {
      &:hover {
        color: variables.$gray-5;
      }
    }
  }

  .str-chat__message--deleted-inner {
    padding: 4px 12px;
    color: variables.$gray-8;
    background-color: variables.$gray-2;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 16px;
  }
}

.str-chat__thread {
  // use @container once https://github.com/GetStream/stream-chat-angular/issues/663 is resolved
  @media (width >= 1700px) {
    // width: 100%;
    // min-width: 0;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
    margin-left: 8px;
    min-width: calc(50% - 8px);
  }

  @media (width < 1700px) {
    position: absolute;
    inset: 0;
    z-index: 10;
    background-color: white;
  }
}

.str-chat__thread-container {  
  .str-chat__thread-header {
    padding-left: 16px;

    .str-chat__thread-header-details {
      gap: 0;

      .str-chat__thread-header-name {
        font-size: 18px;
        line-height: 1.5;
        color: variables.$gray-7;
        font-weight: variables.$font-weight-bold;
      }
  
      .str-chat__thread-header-channel-name {
        font-size: 14px;
        line-height: 1.5;
        color: variables.$gray-6;
      }
    }

    .str-chat__close-thread-button {
      width: 48px;
      height: 48px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: variables.$gray-9;
      background-color: transparent;
      transition-duration: 0.2s;
      outline: none;
      border-radius: 50%;

      &:focus-visible {
        background-color: variables.$gray-1;
      }
      
      @media (hover: hover) {
        &:hover {
          background-color: variables.$gray-1;
        }
      }

      &:active {
        background-color: variables.$gray-2;
      }
    }
  }

  .str-chat__parent-message-li {
    padding-inline: 16px;
    margin-bottom: 0;
  }

  .str-chat__thread-start {
    font-size: 14px;
    line-height: 1.5;
    color: variables.$gray-8;
    user-select: none;
  }

  .str-chat__message-input {
    padding-left: 0;
  }
}

.str-chat__date-separator {
  padding: 16px;
  justify-content: center;
  
  .str-chat__date-separator-line {
    display: none;
  }
  
  .str-chat__date-separator-date {
    color: white;
    background-color: variables.$gray-4;
    font-size: 14px;
    line-height: 1.5;
    font-weight: variables.$font-weight-bold;
    margin: 0;
    padding: 2px 12px;
    border-radius: 14px;
    user-select: none;
  }
}

.str-chat__tooltip {
  background-color: white;
  padding: 8px 12px;
  color: variables.$gray-8;
  font-size: 12px;
  line-height: 1.5;
  text-wrap: pretty;
  text-align: center;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: variables.$radius-medium;
}

.str-chat__avatar {
  user-select: none;

  .str-chat__avatar--online-indicator {
    background-color: variables.$green-success-4;
    border-radius: 50%;
  }
  
  .str-chat__avatar-image {
    border-radius: 50%;
  }
  
  .str-chat__avatar-fallback {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
    background-color: variables.$gray-7;
    font-weight: variables.$font-weight-bold;
  }
}

.str-chat__message-actions-box {
  background-color: white;
  border-radius: variables.$radius-small;
  padding: 8px 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

  .str-chat__message-actions-list {
    button {
      min-height: 48px;
      font-size: 16px;
      color: variables.$gray-8;
      padding: 0 16px;
      background-color: transparent;
      outline: none;
      transition-duration: 0.2s;
  
      &:focus-visible {
        background-color: variables.$gray-1;
      }
  
      @media (hover: hover) {
        &:hover {
          background-color: variables.$gray-1;
        }
      }
  
      &:active {
        background-color: variables.$gray-2;
      }
    }
  }
}

.str-chat__message-input {
  padding: 16px 16px 16px 12px;

  @media (width <= variables.$screen-xs) {
    padding: 8px;
  }

  .str-chat__attachment-preview-list {
    padding: 0 0 16px;

    .str-chat__attachment-list-scroll-container {
      align-items: flex-end;
      padding-bottom: 8px;
    }
  }

  .str-chat__attachment-preview-thumbnail {
    border-radius: 12px;
  }

  .str-chat__attachment-preview-type-file, .str-chat__attachment-preview-type-video {
    color: variables.$gray-9;
    background-color: variables.$gray-1;
    border-radius: 12px;
    height: auto;
    width: 250px;
    padding: 8px;
    padding-right: 24px;
    column-gap: 12px;

    .str-chat__attachment-preview-file-end {
      gap: 0;
      line-height: 1.5;
      color: variables.$gray-8;

      .str-chat__attachment-preview-file-name {
        color: variables.$gray-8;
        font-size: 14px;
        font-weight: variables.$font-weight-bold;
      }

      stream-loading-indicator-placeholder {
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .str-chat__attachment-preview-file-download {
      --str-chat-icon-height: 24px !important;

      color: inherit;
    }
  }

  .str-chat__attachment-preview-delete {
    --str-chat-icon-height: 12px;

    top: 4px;
    right: 4px;
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 2px;
    border-radius: 50%;
    transition-duration: 0.2s;

    &:hover {
      background-color: variables.$red-error-6;
    }
  }

  .str-chat__quoted-message-preview {
    .str-chat__avatar {
      --str-chat__avatar-size: 32px;
    }
  }

  .str-chat__quoted-message-preview-header {
    padding-left: 48px;

    .str-chat__quoted-message-reply-to-message {
      text-align: left;
      font-size: 14px;
      color: variables.$gray-7;
    }

    .str-chat__quoted-message-remove {
      cursor: pointer;
      width: 48px;
      height: 48px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: variables.$gray-9;
      background-color: transparent;
      transition-duration: 0.2s;
      outline: none;
      border-radius: 50%;
  
      &:focus-visible {
        background-color: variables.$gray-1;
      }
      
      @media (hover: hover) {
        &:hover {
          background-color: variables.$gray-1;
        }
      }
  
      &:active {
        background-color: variables.$gray-2;
      }
    }
  }

  .str-chat__quoted-message-bubble {
    color: variables.$gray-9;
    background-color: variables.$gray-1;
    border: 1px solid variables.$gray-2;
    border-radius: $bubble-radius-large $bubble-radius-large $bubble-radius-large $bubble-radius-small;

    .str-chat__quoted-message-text {
      font-size: 16px;
      line-height: 1.5;
    }

    a {
      color: inherit;
    }
  }

  .str-chat__message-input-inner {
    align-items: flex-end;

    .str-chat__file-input-container {
      width: 48px;
      height: 48px;
      margin-bottom: 4px;
      flex-shrink: 0;
      color: variables.$gray-4;

      @media (width <= variables.$screen-xs) {
        margin-right: 8px;
        width: 24px;
        height: 24px;
        margin-bottom: 16px;
      }
    }

    .str-chat__message-textarea-with-emoji-picker {
      padding: 0 8px !important;
      border: 2px solid variables.$gray-3;
      border-radius: variables.$radius-small;
      transition-duration: 0.3s;

      @media (hover: hover) {
        &:hover {
          border-color: variables.$gray-5;
        }
      }

      &:focus-within {
        border-color: variables.$gray-7;
      }

      ::selection {
        color: white;
        background-color: variables.$gray-7;
      }

      .str-chat__textarea {
        .str-chat__message-textarea {
          padding: 14px 8px !important;
          font-size: 16px;
          font-family: inherit;
          line-height: 1.5;
          color: variables.$gray-9;
          caret-color: variables.$gray-9;
          background-color: transparent;
          border: none;
          resize: none;
          outline: none;
  
          &::placeholder {
            color: variables.$gray-6;
          }
        }
      }
    }

    .str-chat__send-button {
      --str-chat-icon-height: 24px;

      width: 48px;
      height: 48px;
      margin-bottom: 4px;
      flex-shrink: 0;
      color: white;
      background-color: variables.$red-primary-5;
      margin-left: 12px;
      border-radius: 50%;
      transition-duration: 0.2s;

      @media (hover: hover) {
        &:hover {
          background-color: variables.$red-primary-6;
        }
      }

      @media (width <= variables.$screen-xs) {
        --str-chat-icon-height: 16px;

        width: 32px;
        height: 32px;
        min-width: unset;
        margin-bottom: 12px;
        margin-left: 8px;
      }
    }
  }
}

.str-chat__quoted-message-bubble {
  br {
    display: none;
  }
}

.str-chat__jump-to-latest-message {
  inset-block-end: 16px;
  inset-inline-end: 24px;

  .str-chat__circle-fab {
    border-radius: 50%;
    color: white;
    background-color: variables.$gray-4;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  }
}

.str-chat__unread-messages-notification {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background-color: white;

  button {
    font-size: 14px;
    line-height: 24px;
    color: variables.$gray-8;
    padding: 12px !important;
    background-color: transparent;
    transition-duration: 0.2s;

    &:focus-visible {
      background-color: variables.$gray-1;
    }

    @media (hover: hover) {
      &:hover {
        background-color: variables.$gray-1;
      }
    }

    &:active {
      background-color: variables.$gray-2;
    }

    &:last-of-type {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.str-chat__modal {
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.35);

  .str-chat__modal__inner {
    padding: 32px variables.$modal-side-padding;
    background-color: white;
    border-radius: variables.$radius-large;
    box-shadow: variables.$box-shadow-modal;
  }
}
